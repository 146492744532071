import { Link } from 'gatsby';
import styled from 'styled-components';

// import helpers
import { getColor, getFontWeight, getBreakpoint } from '../util/theme';

export const DocsWrapper = styled.main`
  max-width: 1200px;

  padding: 0 25px;
  margin: 0 auto;
`;

export const DocsTitle = styled.h1`
  color: ${getColor('darkGrey')};
  font-size: 3rem;
  font-weight: ${getFontWeight('light')};
`;

export const DocsContent = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${getBreakpoint('md')}) {
    flex-direction: row;
  }

  h2 {
    color: ${getColor('darkGrey')};
    font-size: 2rem;
    font-weight: ${getFontWeight('regular')};

    border-bottom: 2px solid ${getColor('grey')};
  }
`;

export const DocsDownload = styled.div`
  flex-basis: 60%;

  padding: 0 100px 0 0;
`;

export const DocLink = styled.a`
  color: ${getColor('darkGrey')};
  font-size: 1.25rem;
  font-weight: ${getFontWeight('medium')};
  text-decoration: none;

  border-bottom: 1px solid ${getColor('grey')};

  padding: 20px 15px;

  display: flex;
  align-items: center;

  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  &:hover {
    background-color: rgba(158, 158, 158, 0.25);
  }

  span {
    &.pdf,
    &.xlsx,
    &.docx {
      border-radius: 3px;

      width: 40px;
      height: 40px;

      margin: 0 15px 0 0;
      padding: 10px;

      display: flex;
      align-items: center;
      justify-content: center;

      &:before {
        color: ${getColor('white')};
        font-size: 0.75rem;
      }
    }
    &.pdf {
      background-color: ${getColor('accentOrange')};

      &:before {
        content: 'PDF';
      }
    }
    &.xlsx {
      background-color: ${getColor('accentGreen')};

      &:before {
        content: 'XLSX';
      }
    }

    &.docx {
      background-color: ${getColor('lightBlue')};

      &:before {
        content: 'DOCX';
      }
    }
  }
`;

export const DocsContact = styled.div`
  flex-basis: 40%;
`;

export const CardContainer = styled.div`
  padding: 16px;
`;

export const ContactCard = styled.div`
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  margin: 20px 15px;
  padding: 15px 25px;

  color: ${getColor('darkGrey')};

  h3 {
    color: ${getColor('black')};
    font-size: 1.5rem;
    font-weight: ${getFontWeight('regular')};

    padding: 0 10px;
  }

  p {
    padding: 0 10px;
  }

  a {
    color: ${getColor('primaryBlue')};
    font-weight: ${getFontWeight('medium')};
    text-decoration: none;
    text-transform: uppercase;

    padding: 10px;

    display: flex;
    align-items: center;

    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

    &:first-of-type {
      margin: 40px 0 0 0;
    }

    &:hover {
      background-color: rgba(158, 158, 158, 0.25);
    }
  }

  svg {
    height: 20px;
    width: 20px;

    margin: 0 10px 0 0;
  }
`;

export const BackBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 20px 0;
`;

export const BackButton = styled(Link)`
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  color: ${getColor('black')};
  font-weight: ${getFontWeight('medium')};
  text-decoration: none;
  text-transform: uppercase;

  padding: 15px 30px;

  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  &:hover {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
`;
