import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import { Email, Phone } from '../components/Svg';

// import components
import {
  DocsWrapper,
  DocsTitle,
  DocsContent,
  DocsDownload,
  DocLink,
  DocsContact,
  CardContainer,
  ContactCard,
  BackBar,
  BackButton,
} from '../components/Base';

const Borrower = ({ data }) => {
  const mapDocs = data => {
    return data.map(datum => {
      const { name, link, type } = datum.node;
      return (
        <DocLink
          key={name}
          href={link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className={type} />
          {name}
        </DocLink>
      );
    });
  };

  const mapContacts = data => {
    return data.map(datum => {
      const { name, title, nmls, email, phone } = datum.node;

      if (name === 'OneTrust Draw Request') {
        return (
          <ContactCard key={name}>
            <h3>{name}</h3>
            <p>
              3131 Camino Del Rio North, Suite 1680
              <br />
              San Diego, CA 92108
            </p>
            <a href={`mailto:${email}`}>
              <Email />
              Email Request Contact
            </a>
            <a href={`tel:${phone}`}>
              <Phone />
              {`CALL ${phone}`}
            </a>
          </ContactCard>
        );
      } else if (name === 'Make a Payment or View Loan Activity') {
        return (
          <ContactCard key={name}>
            <h3>{name}</h3>
            <a
              href="https://www.yourmortgageonline.com/?conn=D7D62635-E348-40D5-8CCF-C4BBDBA8E49E"
              target="_blank"
              rel="noopener noreferrer"
            >
              Go to Website
            </a>
            <p>
              Contact <em>Customer Service</em> for questions regarding your
              mortgage statement, payments, taxes, or insurance at{' '}
              <strong>866-365-1014</strong>
            </p>

            <a href={`tel:${phone}`}>
              <Phone />
              {`CALL ${phone}`}
            </a>
          </ContactCard>
        );
      }

      return (
        <ContactCard key={name}>
          <h3>{name}</h3>
          <p>{title}</p>
          {nmls ? <p>{`NMLS #: ${nmls}`}</p> : null}
          {email ? (
            <a href={`mailto:${email}`}>
              <Email />
              {`EMAIL ${name}`}
            </a>
          ) : null}
          <a href={`tel:${phone}`}>
            <Phone />
            {`CALL ${phone}`}
          </a>
        </ContactCard>
      );
    });
  };

  return (
    <Layout>
      <DocsWrapper id="reach-skip-nav">
        <DocsTitle>
          <strong>Borrower</strong> Resources
        </DocsTitle>
        <DocsContent>
          <DocsDownload>
            <h2>Documents Download</h2>
            {mapDocs(data.allBorrowerDocsJson.edges)}
          </DocsDownload>
          <DocsContact>
            <h2>Contacts</h2>
            <CardContainer>
              {mapContacts(data.allBorrowerContactsJson.edges)}
            </CardContainer>
          </DocsContact>
        </DocsContent>
        <BackBar>
          <BackButton to="/">Back</BackButton>
        </BackBar>
      </DocsWrapper>
    </Layout>
  );
};

export default Borrower;

export const query = graphql`
  {
    allBorrowerDocsJson {
      edges {
        node {
          name
          link
          type
          text
        }
      }
    }

    allBorrowerContactsJson {
      edges {
        node {
          name
          title
          nmls
          email
          phone
        }
      }
    }
  }
`;
